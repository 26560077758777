// Here you can add other styles

//sidebar
.c-sidebar {
  color: #fff;
  background: #024260;

  .c-sidebar-nav-item {
    &:hover {
      color: #fff;
      background: #fea539;
    }
  }
  .c-sidebar-nav-link {
    &:hover {
      color: #fff;
      background: #fea539;
    }
  }
}

.c-sidebar-minimized {
  .c-sidebar-nav-item {
    &:hover {
      color: #fff;
      background: #fea539 !important;
    }
  }
  .c-sidebar-nav-link {
    &:hover {
      color: #fff;
      background: #fea539 !important;
    }
  }
}
.c-sidebar-brand {
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}
.quick-form {
  .select-control.is-invalid {
    border-color: #e55353;
  }

  .select-control {
    z-index: 3 !important;
    color: #768192 !important;
  }

  .tmpImg {
    border: 1px solid #ac9194;
    border-radius: 4px;
    padding: 2px;
    margin: 2px;
    cursor: pointer;

    span {
      position: absolute;

      margin-top: -4px;
      color: red;
      .c-icon {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}

//otp verification

.otpGrp {
  justify-content: space-between;
  .is-invalid {
    background: none !important;
    padding-right: 0 !important;
  }
}

.otpField {
  height: 40px;
  max-width: 40px;
}

//login page

.bg-login-right {
  color: #fff;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.bg-btn-theme {
  color: #fff;
  background-color: #fea539;
  border-color: #fea539;

  &:hover {
    background-color: #e4830c;
    border-color: #e4830c;
    outline: 0;
    box-shadow: none;
  }
  &:active {
    background-color: #e4830c !important;
    border-color: #e4830c !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:focus {
    background-color: #e4830c !important;
    border-color: #e4830c !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.bg-btn-create {
  color: #fff;
  background-color: #74b42e;
  border-color: #74b42e;

  &:hover {
    background-color: #609726;
    border-color: #609726;
    outline: 0;
    box-shadow: none;
  }
  &:active {
    background-color: #609726 !important;
    border-color: #609726 !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:focus {
    background-color: #609726 !important;
    border-color: #609726 !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.bg-btn-filter {
  color: #fff;
  background-color: #f2642c;
  border-color: #f2642c;

  &:hover {
    background-color: #ee4603;
    border-color: #ee4603;
    outline: 0;
    box-shadow: none;
  }
  &:active {
    background-color: #ee4603 !important;
    border-color: #ee4603 !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:focus {
    background-color: #ee4603 !important;
    border-color: #ee4603 !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.bg-btn-generate {
  color: #fff;
  background-color: #024260;
  border-color: #024260;

  &:hover {
    background-color: #02293b;
    border-color: #02293b;
    outline: 0;
    box-shadow: none;
  }
  &:active {
    background-color: #02293b !important;
    border-color: #02293b !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:focus {
    background-color: #02293b !important;
    border-color: #02293b !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
}
.link-theme {
  color: #778192;

  &:hover {
    color: #778192;
    text-decoration: none;
    color: #e4830c;
  }
}
.form-control {
  &:focus {
    box-shadow: none !important;
    outline: 0 !important;
    border-color: #fea539;
  }
  &:active {
    box-shadow: none !important;
    outline: 0 !important;
    // border-color: #FEA539;
  }
}

//pagination

.pagination {
  .page-item.active .page-link {
    background-color: #024260;
    border-color: #024260;
  }
}

//links

a {
  text-decoration: none;
  background-color: transparent;
  color: #024260;
}
//customer details
.custDetails {
  .aliasAvatar {
    display: flex;
    align-items: center;
  }
  .aliasName {
    margin-left: 2%;
  }

  @media screen and (max-width: 576px) {
    .aliasAvatar {
      display: inline-block;
      align-items: unset;
    }
    .aliasName {
      margin-left: 0;
    }
  }

  span {
    color: #5f5f5f;
    opacity: 0.8;
    font-size: 13px;
  }
  .info {
    opacity: 0.5;
  }
  h4 {
    font-size: 0.85rem;
    margin-bottom: 30px;
    color: #171717;
  }
  .c-icon {
    color: #5bd104;
  }
}
.custLogo {
  width: 65px;
  height: 65px;
  padding: 5px 0;

  img {
    height: 100%;
    width: 100%;
  }
}
.custEditBtn {
  border: 1px solid #024260;
  color: #171717;
  &:hover {
    outline: 0;
    box-shadow: none;
  }
  &:active {
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

.modal-theme {
  .bg-theme {
    color: #fff;
    background-color: #fea539;
    border-color: #fea539;
  }
}

.page-heading {
  color: #f2642c;
  font-weight: 600;
}
.page-heading1 {
  color: #020202;
  font-weight: 600;
}
.page-heading2 {
  font-weight: 600;
}
.c-switch-primary .c-switch-input:checked + .c-switch-slider {
  background-color: #74b42e;
  border-color: #74b42e;
}
.c-switch-primary .c-switch-input + .c-switch-slider {
  background-color: #ced2d8;
  border-color: #ced2d8;
}
.c-switch-slider {
  outline: none !important;
  box-shadow: none !important;

  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

ul.location-list {
  padding: 0 0 0 15px;
  margin-bottom: 5px;
}

.drop-action-table {
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent !important;
  }
  .table-striped tbody tr:nth-of-type(4n + 1) {
    background-color: rgba(0, 0, 21, 0.05) !important;
  }
}
.pointer {
  cursor: pointer !important;
}

.shimmer {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: transparent;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  }

  .row {
    * * {
      color: transparent;
      background: linear-gradient(
        to right,
        #eff1f3 4%,
        #e2e2e2 25%,
        #eff1f3 36%
      );
      animation: shimmer 8s infinite;
    }
  }
  td,
  th {
    color: transparent;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.drag-container {
  padding: 0;
  li {
    list-style-type: none;
    padding: 5px;
    .card {
      margin: 0;
      .card-header,
      .card-body {
        padding: 5px 10px;
        background-color: #5ecc82;
        color: #000;
        font-size: 0.8rem;
      }

      .card-header {
        border-bottom: 0;
      }

      &:hover {
        cursor: grabbing;
      }

      box-shadow: 10px 10px 4px -2px rgba(0, 0, 0, 0.55);
      -webkit-box-shadow: 10px 10px 4px -2px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 10px 10px 4px -2px rgba(0, 0, 0, 0.55);
    }
  }
}
.badge-secondary {
  color: #f8f8f8;
  background-color: #ed37ea;
}

.review-container {
  text-align: center;
  border: 2px solid #ccc;

  border-radius: 10px;
  padding: 10px 0;
  hr {
    margin-top: 5px;
  }
}

.reports {
  select {
    min-width: 100px;
  }
  input {
    min-width: 100px;
  }
}
.dashCardHeader {
  color: #f2642c;
  font-weight: 600;
  border-bottom: 0;
}

.dashCardWidget {
  border: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .card-header {
    border-radius: 10px 10px 0 0 !important;
  }
}
.dash-box {
  border: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  th {
    font-size: 0.8rem;
  }
}
.chart-header {
  padding: 10px 0 10px 10px;
}
.chart-indics {
  display: flex;
  color: #b2b2b2;
  font-size: 0.8rem;
  justify-content: center;

  div {
    margin-right: 15px;
  }

  .indic {
    margin-right: 5px;

    padding: 0 7px;
    border-radius: 50%;
  }
}

.bg-indic-1 {
  background-color: #74b42e;
}
.bg-indic-2 {
  background-color: #f5a53a;
}
.bg-indic-3 {
  background-color: #ed6437;
}
.bg-indic-4 {
  background-color: #20a8d8;
}
.bg-indic-5 {
  background-color: #4dbd74;
}
.bg-indic-6 {
  background-color: #f86c6b;
}
.bg-indic-7 {
  background-color: #10425e;
}

.logo-thumb {
  border: 1px solid #d8dbe0;
  width: 125px;
  height: 125px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-size: cover;
  &:hover {
    color: #fff;
  }
  cursor: pointer;
  .logo-over-lay {
    height: 125px;
    width: 125px;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 0.4766281512605042) 0%,
      rgba(48, 52, 57, 0.0032387955182072714) 70%
    );
    position: absolute;
  }
}
